import React from 'react'
import _ from 'lodash'
import BackgroundImage from 'gatsby-background-image'
import { Grid } from '@material-ui/core'
import { arrayHasEntry } from '../utils'

import MenuItem from './MenuItem'
import {
  ContentfulComponentCard,
  ContentfulComponentImage,
  ContentfulComponentNavBar,
  ContentfulComponentText,
  ContentfulComponentLink,
  ContentfulComponentEmbed,
  ContentfulComponentAccordion,
  ContentfulComponentCarousel,
} from './ContentfulComponents'

function GridItem({
  __typename,
  cardBody,
  cardFooter,
  cardHeader,
  icon,
  className,
  backgroundImage,
  cardItems,
  body,
  id,
  props,
  linkImage,
  title,
  link,
  image,
}) {
  switch (__typename) {
    case 'ContentfulComponentCard':
      return (
        <ContentfulComponentCard
          cardBody={cardBody}
          cardFooter={cardFooter}
          cardHeader={cardHeader}
          icon={icon}
          className={className}
          cardItems={cardItems}
          backgroundImage={backgroundImage}
        />
      )
    case 'ContentfulComponentText':
      return (
        <ContentfulComponentText
          className={_.get(props, 'className') || null}
          key={`component-${id}`}
          {...body}
        />
      )
    case 'ContentfulComponentLink':
      return (
        <ContentfulComponentLink
          key={`component-${id}`}
          linkImage={linkImage}
          externalLink={_.get(props, 'externalLink', '')}
          externalLinkFr={_.get(props, 'externalLinkFr', '')}
          title={title}
          link={link}
          className={_.get(props, 'className', '')}
        />
      )
    case 'ContentfulComponentImage':
      return (
        <ContentfulComponentImage
          className={_.get(props, 'className') || null}
          key={`component-${id}`}
          {...image}
        />
      )
    default:
      break
  }
  return null
}

function SectionMenu({ menuItems }) {
  return (
    <div className="footer-menu-inner">
      {arrayHasEntry(menuItems) &&
        menuItems.map(menuItem => (
          <MenuItem key={`menuItem-${menuItem.id}`} {...menuItem} />
        ))}
    </div>
  )
}

function SectionGridContainer({ gridItems, className }) {
  return (
    <Grid justify="center" className={className} container spacing={0}>
      {arrayHasEntry(gridItems) &&
        gridItems.map(gridItem => (
          <Grid
            item
            lg={
              parseInt(_.get(gridItem, 'props.columnWidth.lg', false)) || false
            }
            md={
              parseInt(_.get(gridItem, 'props.columnWidth.md', false)) || false
            }
            sm={
              parseInt(_.get(gridItem, 'props.columnWidth.sm', false)) || false
            }
            xs={
              parseInt(_.get(gridItem, 'props.columnWidth.xs', false)) || false
            }
            key={`gridItem-${gridItem.id}`}
            className={_.get(gridItem, 'props.className', null)}
          >
            <GridItem
              className={_.get(gridItem, 'props.className', null)}
              {...gridItem}
            />
          </Grid>
        ))}
    </Grid>
  )
}

function sectionComponentPicker({
  __typename,
  id,
  body,
  cardBody,
  cardFooter,
  cardHeader,
  cardItems,
  icon,
  image,
  gridItems,
  menuItems,
  navBarItems,
  linkImage,
  title,
  link,
  props,
  embedCode,
  accordionItem,
  carouselItems,
}) {
  switch (__typename) {
    case 'ContentfulComponentCard':
      return (
        <ContentfulComponentCard
          cardBody={cardBody}
          cardFooter={cardFooter}
          cardHeader={cardHeader}
          cardItems={cardItems}
          icon={icon}
          className={_.get(props, 'className') || null}
          key={`component-${id}`}
        />
      )
    case 'ContentfulComponentGridContainer':
      return (
        <SectionGridContainer
          key={`component-${id}`}
          className={_.get(props, 'className') || null}
          gridItems={gridItems}
        />
      )
    case 'ContentfulComponentImage':
      return (
        <ContentfulComponentImage
          className={_.get(props, 'className') || null}
          externalLink={_.get(props, 'externalLink') || null}
          ariaLabel={_.get(props, 'ariaLabel') || null}
          key={`component-${id}`}
          {...image}
        />
      )
    case 'ContentfulComponentMenu':
      return <SectionMenu key={`component-${id}`} menuItems={menuItems} />
    case 'ContentfulComponentNavBar':
      return (
        <ContentfulComponentNavBar
          key={`component-${id}`}
          navBarItems={navBarItems}
        />
      )
    case 'ContentfulComponentText':
      return (
        <ContentfulComponentText
          className={_.get(props, 'className') || null}
          key={`component-${id}`}
          {...body}
        />
      )
    case 'ContentfulComponentLink':
      return (
        <ContentfulComponentLink
          key={`component-${id}`}
          linkImage={linkImage}
          externalLink={_.get(props, 'externalLink', '')}
          externalLinkFr={_.get(props, 'externalLinkFr', '')}
          title={title}
          link={link}
          className={_.get(props, 'className', '')}
        />
      )
    case 'ContentfulComponentEmbed':
      return <ContentfulComponentEmbed key={`component-${id}`} {...embedCode} />
    case 'ContentfulComponentAccordion':
      return (
        <ContentfulComponentAccordion
          key={`component-${id}`}
          accordionItems={[...accordionItem]}
        />
      )
    case 'ContentfulComponentCarousel':
      return (
        <ContentfulComponentCarousel
          key={`component-${id}`}
          carouselItems={[...carouselItems]}
        />
      )
    default:
      break
  }
  return null
}

function createMarkup(markup) {
  return { __html: markup }
}

function SectionWrapper({
  backgroundImage,
  children,
  description,
  props,
  lang,
}) {
  const htmlDescription = _.get(description, 'childMarkdownRemark.html')
  const classNames = props ? props.className : null
  const idName = props ? props.idName : null
  const landmark = props ? props.landmark : null
  const imageData = _.get(backgroundImage, 'localFile.childImageSharp.fluid')
  if (imageData) {
    return (
      <BackgroundImage
        Tag="section"
        className={classNames}
        fluid={imageData}
        id={idName}
      >
        {htmlDescription && (
          <div
            dangerouslySetInnerHTML={createMarkup(
              _.get(description, 'childMarkdownRemark.html')
            )}
          />
        )}
        <div className="section-children-wrapper">{children}</div>
      </BackgroundImage>
    )
  } else if (landmark === 'header') {
    return (
      <header
        lang={lang}
        style={{
          backgroundImage: backgroundImage
            ? `url('${backgroundImage.fluid.src}')`
            : undefined,
        }}
        className={classNames}
        id={idName}
      >
        {htmlDescription && (
          <div
            dangerouslySetInnerHTML={createMarkup(
              _.get(description, 'childMarkdownRemark.html')
            )}
          />
        )}
        <div className="section-children-wrapper">{children}</div>
      </header>
    )
  } else if (landmark === 'footer') {
    return (
      <footer
        lang={lang}
        style={{
          backgroundImage: backgroundImage
            ? `url('${backgroundImage.fluid.src}')`
            : undefined,
        }}
        className={classNames}
        id={idName}
      >
        <hr />
        {htmlDescription && (
          <div
            dangerouslySetInnerHTML={createMarkup(
              _.get(description, 'childMarkdownRemark.html')
            )}
          />
        )}
        <div className="section-children-wrapper">{children}</div>
      </footer>
    )
  } else {
    return (
      <section
        lang={lang}
        style={{
          backgroundImage: backgroundImage
            ? `url('${backgroundImage.fluid.src}')`
            : undefined,
        }}
        className={classNames}
        id={idName}
      >
        {htmlDescription && (
          <div
            dangerouslySetInnerHTML={createMarkup(
              _.get(description, 'childMarkdownRemark.html')
            )}
          />
        )}
        <div className="section-children-wrapper">{children}</div>
      </section>
    )
  }
}

export default function({ sections, node_locale }) {
  return (
    <main>
      {sections.map(({ components, id, ...rest }) => (
        <SectionWrapper {...rest} lang={node_locale} key={`section-${id}`}>
          {arrayHasEntry(components) && components.map(sectionComponentPicker)}
        </SectionWrapper>
      ))}
    </main>
  )
}
