import React from 'react'
import styled from 'styled-components'

const EmbedWrapper = styled.div`
  text-align: center;
`
export default function({ embedCode }) {
  return (
    <EmbedWrapper>
      {embedCode && <div dangerouslySetInnerHTML={{ __html: embedCode }} />}
    </EmbedWrapper>
  )
}
