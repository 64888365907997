function prettyPrint(obj) {
  console.log(JSON.stringify(obj, null, 4))
}

function arrayHasEntry(array) {
  return array && array.length > 0
}

module.exports = {
  arrayHasEntry,
  prettyPrint,
}
