import React, { Fragment } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { arrayHasEntry } from '../utils'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import LanguageContext from '../components/Context'

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    position: unset;
  }

  > a {
    padding: 15px;
    @media screen and (max-width: 768px) {
      padding: 11px;
    }
    &:hover {
      text-decoration: none;
      background: #128fee;
      color: #ffffff;
      @media screen and (min-width: 768px) {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.21);
        color: #128fee;
        background: #ffffff;
      }
    }
  }
  span {
    padding: 15px;
    @media screen and (max-width: 480px) {
      padding: 0px;
    }
    @media screen and (min-width: 481px) and (max-width: 780px) {
      padding: 8px;
    }
    &.active,
    &:hover {
      text-decoration: none;
      background: #128fee;
      color: #ffffff;
      @media screen and (min-width: 768px) {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.21);
        color: #128fee;
        background: #ffffff;
      }
      svg {
        color: #cf2518;
      }
    }
  }
`

const MenuGroup = styled.div`
  display: flex;
  position: absolute;
  top: 52px;
  z-index: 1;
  background-color: #fff;
  color: #000;
  border-top: 6px solid #cf2518;
  left: -150%;
  right: -100%;
  margin-left: auto;
  margin-right: auto;
  width: 540px;

  @media screen and (max-width: 320px) {
    flex-direction: column;
    width: 100vw;
    top: 172px;
  }
  @media screen and (min-width: 321px) and (max-width: 500px) {
    flex-direction: column;
    width: 100vw;
    top: 175px;
  }
  @media screen and (min-width: 320px) and (max-width: 482px) {
    color: #000;
    &:lang(fr-CA) {
      top: 216px;
    }
  }
  @media screen and (min-width: 483px) and (max-width: 586px) {
    color: #000;
    &:lang(fr-CA) {
      top: 174px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 940px) {
    color: #000;
    &:lang(fr-CA) {
      top: 72px;
    }
  }
  @media screen and (min-width: 940px) and (max-width: 1110px) {
    color: #000;
    &:lang(fr-CA) {
      top: 62px;
    }
  }
  @media screen and (min-width: 500px) and (max-width: 711px) {
    top: 153px;
    flex-direction: column;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    left: -100%;
  }
  @media screen and (min-width: 520px) and (max-width: 768px) {
    top: 153px;
    flex-direction: column;
    width: 100vw;
  }
  @media screen and (min-width: 769px) and (max-width: 780px) {
    top: 45px;
  }
`

const MenuSubGroupWrapper = styled.div`
  padding: 25px;
  width: 180px;
  @media screen and (max-width: 768px) {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 0px;
  }
`

const MenuSubGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  @media (max-width: 768px) {
    padding-top: 0px !important;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
    text-transform: capitalize;
    font-weight: 400;

    &:hover,
    &.active,
    &:focus {
      color: #128fee;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`

const MenuSubGroupHeader = styled.div`
  color: #e13426;
  text-transform: capitalize;
  a {
    display: inline-block;
    width: 100%;
    @media screen and (max-width: 768px) {
      padding: 25px;
    }
  }
`

const SimpleMenuGroup = styled.div`
  &:nth-child(-n + 4) {
    flex: 0 0 33.333%;
  }
  .title {
    color: #df3127;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.47px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-decoration: none;
  }
  a.title {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &:lang(fr-CA) {
    .menu-item {
      padding-right: 12px;
      line-height: 20px;
    }
  }
  .menu-item {
    display: block;
    color: #24252a;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.41px;
    margin: 7px 0;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &.col-5 {
    .title {
      display: none;
    }
    .menu-item {
      color: #df3127;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.47px;
      margin-bottom: 20px;
      text-transform: uppercase;
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
`

function GetLinkTo(subSubMenuItem) {
  const { languageState } = React.useContext(LanguageContext)
  if (subSubMenuItem.props.externalLink) {
    return _.get(subSubMenuItem, 'props.externalLink', '/')
  } else if (subSubMenuItem.props.linkTo) {
    return languageState.lang === true
      ? '' + _.get(subSubMenuItem, 'props.linkTo', '/')
      : '/fr' + _.get(subSubMenuItem, 'props.linkTo', '/')
  }
}

const getId = (openMenu, id) => {
  let openMenuId = null
  if (!openMenu) {
    openMenuId = id
  }
  return openMenuId
}

export default function MenuItem({
  id,
  enableDropdown,
  openMenu,
  setOpenMenu,
  submenuItems,
  title,
  props,
  link,
}) {
  const { languageState } = React.useContext(LanguageContext)

  if (!enableDropdown) {
    return (
      <SimpleMenuGroup className={_.get(props, 'className') || ''}>
        {link && title && (
          <a
            href={
              languageState.lang === true ? '' + link.slug : '/fr/' + link.slug
            }
            className="title"
          >
            {title}
          </a>
        )}
        {arrayHasEntry(submenuItems) &&
          submenuItems.map((submenuItem, submenuItemIndex) => (
            <a
              href={GetLinkTo(submenuItem)}
              className="menu-item"
              key={`submenuitem-${submenuItemIndex}`}
            >
              {submenuItem.title}
            </a>
          ))}
      </SimpleMenuGroup>
    )
  }

  return (
    <DropdownWrapper
      className={openMenu ? 'active' : null}
      onClick={() => setOpenMenu(getId(openMenu, id))}
    >
      {arrayHasEntry(submenuItems) ? (
        <a href="javascript:;">{title}</a>
      ) : (
        <a
          href={
            languageState.lang === true ? '' + link.slug : '/fr/' + link.slug
          }
        >
          {title}
        </a>
      )}
      {arrayHasEntry(submenuItems) && (
        <div>
          {openMenu ? (
            <Fragment>
              <ExpandLess />
              {arrayHasEntry(submenuItems) && (
                <MenuGroup>
                  {submenuItems.map((submenuItem, submenuItemIndex) => (
                    <MenuSubGroupWrapper
                      key={`submenuitem-${submenuItemIndex}`}
                    >
                      <MenuSubGroupHeader>
                        {submenuItem.link && submenuItem.link.slug ? (
                          <Link
                            to={`/${
                              languageState.lang === true
                                ? '' + submenuItem.link.slug
                                : 'fr/' + submenuItem.link.slug
                            }`}
                            key={`subMenuItem-${submenuItem.id}`}
                          >
                            {submenuItem.title}
                          </Link>
                        ) : (
                          <div>{submenuItem.title}</div>
                        )}
                      </MenuSubGroupHeader>

                      {submenuItem.submenuItems &&
                        submenuItem.submenuItems.length > 0 && (
                          <MenuSubGroup>
                            {submenuItem.submenuItems.map(subSubMenuItem => (
                              <Link
                                to={GetLinkTo(subSubMenuItem)}
                                key={`subSubMenuItem-${subSubMenuItem.id}`}
                                style={{
                                  padding: '8px 0',
                                }}
                              >
                                {subSubMenuItem.title}
                              </Link>
                            ))}
                          </MenuSubGroup>
                        )}
                    </MenuSubGroupWrapper>
                  ))}
                </MenuGroup>
              )}
            </Fragment>
          ) : (
            <ExpandMore />
          )}
        </div>
      )}
    </DropdownWrapper>
  )
}
