import React from 'react'
import Img from 'gatsby-image'

export default function({ ...rest }) {
  const {
    fluid,
    altText,
    file,
    localFile,
    className,
    externalLink,
    ariaLabel,
  } = rest
  if (localFile && localFile.childImageSharp) {
    return externalLink ? (
      <span className="gatsby-outer-img-wrapper">
        <a
          href={externalLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLabel ?? 'Link to external site'}
        >
          <Img
            fluid={localFile.childImageSharp.fluid}
            alt={altText ? altText : ''}
            withWebp={true}
            className={className}
          />
        </a>
      </span>
    ) : (
      <span className="gatsby-outer-img-wrapper">
        <Img
          fluid={localFile.childImageSharp.fluid}
          alt={altText ? altText : ''}
          withWebp={true}
          className={className}
        />
      </span>
    )
  } else if (fluid && fluid.src) {
    return (
      <img
        src={fluid.src}
        srcSet={fluid.srcSet}
        alt={altText ? altText : ''}
        className={className}
        style={{ maxWidth: '100%' }}
      />
    )
  } else if (localFile && localFile.publicURL) {
    return (
      <img
        src={localFile.publicURL}
        srcSet={localFile.publicURL}
        alt={altText ? altText : ''}
        className={className}
      />
    )
  } else if (file && file.url) {
    return (
      <img src={file.url} alt={altText ? altText : ''} className={className} />
    )
  }
  return null
}
