import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import Image from '../Image'

import { arrayHasEntry } from '../../../utils'

import './Carousel.scss'

const CarouselWrapper = styled.div``
const CarouselItem = styled.div``

const sliderSetting = {
  dots: true,
  autoplay: true,
}

export default function({ carouselItems }) {
  return (
    <CarouselWrapper>
      <Slider {...sliderSetting}>
        {arrayHasEntry(carouselItems) &&
          carouselItems.map(carouselItem => (
            <CarouselItem
              title={carouselItem.title}
              key={`carouselItem-${carouselItem.id}`}
            >
              {carouselItem.image && (
                <Image {...carouselItem.image} alt={carouselItem.image.title} />
              )}
            </CarouselItem>
          ))}
      </Slider>
    </CarouselWrapper>
  )
}
