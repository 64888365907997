import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import BackgroundImage from 'gatsby-background-image'

import ContentfulComponentImage from '../Image'
import ContentfulComponentText from '../Text'
import ContentfulComponentModal from '../Modal'
import LinkWithIcon from '../LinkWithIcon'

const Card = styled.div`
  overflow: hidden;
  .card-content {
    display: block;
  }
  .card-footer {
    a { 
      display: block;
      transition: all 0.3s ease;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.support-group-grid-card,
  &.manufacturers-grid-card,
  &.patients-grid-card,
  &.physicians-grid-card,
  &.key-assets-grid-card,
  &.about-us-grid-card,
  &.about-us-grid-card-2,
  &.promise-grid-card {
    border: none;
    text-align: center;
    padding: 15px;

    .card-header {
      img {
        height: auto;
        width: 115px;
      }
      h4 {
        font-size: 24px;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 32px;
        }    
        font-weight: 300;
        color: #24252A;
        text-transform: uppercase;
      }
      .small-title {
        font-size: 24px;
        font-weight: 300;
        color: rgb(36, 37, 42);
        text-transform: uppercase;
      }
    }

    .card-body {
      font-size: 14px;  
      line-height: 27px;
      color: rgba(0,0,0,0.54);
    }

    @media screen and (max-width: 600px) {
      .card-body {
        p {
          display: none;
        }
      }
    }

    .card-footer {
      font-size: 14px;  
      font-weight: bold;  
      letter-spacing: 1.26px;
      color: #CF2518;
      text-shadow: 0 1px 2px 0 rgba(91,12,12,0.5);
      padding: 0 15%;
      text-transform: uppercase;

      img {
        display: block;
        margin: auto;
        max-width: 75px;
        height: auto;
        margin-top:20px;
      }
    }
  }

  &.about-us-grid-card {
    .gatsby-outer-img-wrapper {
      width: 134px;
      display: inline-block;
    }
  }

    @media screen and (min-width: 600px) {
    &.about-us-grid-card-2 {
      .card-header {
        h4 {
          height: 81px;
        }
      }
      .card-body {
        p {
          height: 81px;
        }
      }
    }
  }

  &.key-assets-grid-card {
    .gatsby-outer-img-wrapper {
      display: inline-block;
      img {
        width: unset!important;
      }
    }
    .gatsby-image-wrapper {
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
    .card-header {
      margin-top: 30px;
    }
  }

  &.support-group-grid-card {
    height: 100%;
    display: flex;
    .card-content {
      display: flex;
      flex-direction: column;
      .arrow-link {
        margin-top: auto;
      }
    }
    .card-header img {
      width: 115px;
      height: 87px;
    }
    .gatsby-outer-img-wrapper {
      width: 160px;
      display: inline-block;
    }
  }

  &.why-join-grid-card {
    .gatsby-outer-img-wrapper {
      display: inline-block;
    }
  }

  &.patients-grid-card {
    .gatsby-outer-img-wrapper {
      width: 132px;
      display: inline-block;
    }
  }

  &.promise-grid-card {
    .gatsby-outer-img-wrapper {
        display: inline-block;
        margin-bottom: 30px;
        img {
          width: unset!important;
        }
      }
      .gatsby-image-wrapper {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
  }

  &.manufacturers-grid-card, &.patients-grid-card {
    .card-header {
      h4 {
        height: 80px;
      }
    }
    .card-body {
      @media screen and (max-width: 1024px) {
        min-height: 170px;
      }
      @media screen and (min-width: 1025px) {
        min-height: 200px;
      }
    }
    @media screen and (max-width: 600px) {
      .card-header {
        h4 {
          height: unset;
        }
      }
      .card-body {
        min-height: unset;
      }
    }
    .card-footer {
      img {
        width: 18px;
      }
    }
  }

  &.physicians-grid-card {
    .card-body {
      @media screen and (max-width: 1024px) {
        min-height: 120px;
      }
      @media screen and (min-width: 1025px) {
        min-height: 150px;
      }
    }
    @media screen and (max-width: 600px) {
      .card-body {
        min-height: unset;
      }
    }
  }

  &.team-grid-card {
    border: none;
    text-align: center;
    padding: 15px;
    // cursor: pointer;
    // &:hover {
    //   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.21);
    // }
    p { margin: 0}
    .gatsby-outer-img-wrapper {
      display: inline-block;
      width: 150px;
      margin-bottom: 15px;
    }
    .card-header {
      img {
        height: 130px;
        width: 130px;
      }
      p {
        font-size: 24px;
        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 32px;
        }    
        font-weight: 300;
        color: #24252A;
        text-transform: uppercase;
        margin: 15px 0;
      }
    }

    .card-body {
      font-size: 14px;  
      line-height: 27px;
      color: rgba(0,0,0,0.54);
      max-width: 200px;
      margin: auto;
    }

    .card-footer {
      font-size: 14px;  
      font-weight: bold;  
      letter-spacing: 1.26px;
      color: #CF2518;
      text-shadow: 0 1px 2px 0 rgba(91,12,12,0.5);
      padding: 0 15%;
      text-transform: uppercase;
      margin-top: 15px;
      img {
        display: block;
        margin: auto;
        width: 34px;
        height: 34px;
        margin-top: 10px;
      }
    }
  }

  &.specialties-grid-card.childSwoop,
  &.why-shn-grid-card.childSwoop,
  &.sdm-advantage-grid-card.childSwoop {
    .gatsby-image-wrapper {
      position: absolute!important;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &.why-shn-grid-card {
    a {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      span {
        padding: 0px;
      }
      img {
        margin-left: 16px;
        height: 16px;
      }
    }
  }

  &.why-shn-grid-card,
  &.sdm-advantage-grid-card,
  &.specialties-grid-card {
    padding: 10vh 5vh 8vh;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: bold;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    @media screen and (min-width: 960px) {
      margin-right: 5px;
      padding: 10vh 2vh 8vh;
      &.rounded-border-left {
        border-bottom-left-radius: 100% 13%;
      }

      &.rounded-border-right {
        margin-right: 0px;
        border-bottom-right-radius: 100% 13%;
      }
    }

    h4 {
      font-size: 36px;  
      font-weight: 600;
    }
  }

  &.why-shn-grid-card, &.specialties-grid-card {
    min-height: 575px;
  }

  &.reach-grid-card, &.why-join-grid-card {
    border-radius: 5px;
    @media screen and (max-width: 1200px) {
      box-shadow: 5px 1px 12px 0px #d2d2d2;
      .gatsby-outer-img-wrapper {
        width: 100%;
      }
    }
    @media screen and (min-width: 1200px) {
      .gatsby-image-wrapper {
        width: 350px;
    }
  }
}

  &.sdm-advantage-grid-card {
    &:lang(fr-CA) {
      .large-number {
        font-size: 45px;
        line-height: 53px;
      }
      .unit {
        font-size: 26px;
        line-height: 34px;
      }
    }
    padding-top: 330px;
    @media screen and (max-width: 960px) {
      padding-top: 45vh;
    }
    @media screen and (min-width: 960px) and (max-height: 880px) {
      padding-top: 39vh;
    }
    @media screen and (min-width: 1150px) and (max-height: 880px) { 
      padding-top: 40vh;
    }
    min-height: 700px;
  }

  .notModal {
    border-top: 1px solid #acabbd;
    span {
      border: unset;
      color: #d0021b;
      font-size: 14px;
      line-height: 28px;
      font-weight: 600;
      cursor: pointer;
    }
    .accordion-title {
      background-color: #fafafa;
      padding: 18px;
      color: #d0021b;
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
      > div {
        margin: 0px;
        padding: 0px;
      }
    }
    .accordion-summary {
      background-color: #f0f0f0;
      font-size: 14px;
    }
  }

  &.best-grid-card,
  &.features-grid-card {
    border-radius: 10px;  
    background-color: #FAFAFA;  
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    border-bottom: 10px solid #4A90E2;
    margin: 20px;
    min-height: 350px;
    height: calc(100% - 40px);
    padding: 40px 15px 20px;
    text-align: center;
    cursor: pointer;
    &:hover {
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.6);
    }
    h4 {
      text-transform: uppercase;
      font-size: 24px;
      @media screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 32px;
      }  
      font-weight: 300;  
      letter-spacing: 0.44px;  
      line-height: 35px;
      color: #24252A;
      max-width: 85%;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      font-size: 14px;  
      line-height: 27px;
      color: rgba(0,0,0,0.54);
    }
    .modal-content {
      cursor: default;
      p {
        color: rgba(0,0,0,0.8);
      }
      ul {
        text-align: left;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          flex: 0 0 45%;
        }
      }
    }
    .card-footer {
      p {
        font-size: 14px;
        font-weight: bold;
        border: none;
        background: none;
        letter-spacing: 1.26px;
        color: #CF2518;
        padding: 0 15%;
        text-transform: uppercase;
        margin-top: 15px;
        img {
          display: block;
          margin: auto;
          width: 34px;
          height: 34px;
          margin-top: 10px;
        }
      }
    }
  }

  &.features-grid-card {
    cursor: auto;
    padding: 0;
    .card-header {
      max-height: 280px;
      overflow: hidden;
      p {
        margin: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .card-body {
      padding: 20px;
      p {
        font-size: 20px;
      }
    }
    h4 {
      font-weight: 400;
      font-size: 28px;
    }
  }

  &.reach-grid-card { 
    .card-header {
      &:lang(fr-CA) {
        font-size: 32px;
      }
    }
  }
  &.why-join-grid-card,
  &.reach-grid-card {
    position: relative;
    margin: 30px 0;
    @media (max-width: 1201px) {
      text-align: center;
    }

    .card-header {
      font-size: 36px;  
      line-height: 48px;
      color: #3E3F43;
      width: 285px;
      position: absolute;
      left: 0;
      top: 20%;
      bottom: 20%;
      @media (max-width: 1201px) {
        position: static;
        width: auto;
        margin-top: 20px;
      }
    }

    .card-content {
      background-color: rgba(155,155,155,0.1);
      margin-left: 85px;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      @media (max-width: 1200px) {
        border-bottom: 17px solid #4A90E2;
        padding: 0px!important;
      }
      @media (min-width: 1200px) {
        border-right: 17px solid #4A90E2;
      }
      @media (max-width: 1201px) {
        margin-left: 0;
        flex-direction: column;
        padding: 15px;
        align-items: center;
      }
      .gatsby-outer-img-wrapper {
        display: inline-block;
      }
      img {
        align-self: center;
        max-width: 100%;
      }
    }

    .card-body {
      padding: 40px 50px 40px 210px;
      @media (max-width: 1201px) {
        padding: 15px;
      }
    }
  }
  &.looking-back-grid-card {
    border-radius: 5px;  
    background-color: #FAFAFA;  
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    margin: 15px;
    padding: 25px 25px 5px;
    @media (min-width: 1280px) {
      padding: 100px 25px 5px;
    }
    height: calc(100% - 30px);
    .card-body {
      font-size: 14px;
      text-align: center;
    }
  }
  &.best-grid-card {
    .card-footer {
      p {
        button {
          font-size: 14px;
          font-weight: bold;
          border: none;
          background: none;
          letter-spacing: 1.26px;
          color: #CF2518;
          text-transform: uppercase;
          margin-top: 15px;
        img {
          display: block;
          margin: auto;
          width: 34px;
          height: 34px;
          margin-top: 10px;
        }
      }
    } 
  } 
    @media screen and (max-width: 599px) {
      .card-header, .card-footer, .card-body, h3 {
        display: none;
      }
    }
  }
`

function CardItem({
  __typename,
  image,
  modalContent,
  linkImage,
  title,
  link,
  props,
}) {
  const className = props ? props.className : null
  const externalLink = props ? props.externalLink : null
  const ariaLabel = props ? props.ariaLabel : null
  switch (__typename) {
    case 'ContentfulComponentLink':
      if (linkImage) {
        return (
          <LinkWithIcon
            className={className}
            linkImage={linkImage}
            title={title}
            link={link}
          />
        )
      } else {
        // Without any image
        return <LinkWithIcon className={className} title={title} link={link} />
      }
    case 'ContentfulComponentImage':
      return (
        <ContentfulComponentImage
          className={className}
          externalLink={externalLink}
          ariaLabel={ariaLabel}
          {...image}
        />
      )
    case 'ContentfulComponentModal':
      return (
        <div>
          <ContentfulComponentModal
            content={modalContent}
          ></ContentfulComponentModal>
        </div>
      )
    default:
      return null
  }
}

function hasParentWithClass(element, classname) {
  if (element.className.split(' ').indexOf(classname) >= 0) return true
  return (
    element.parentElement &&
    hasParentWithClass(element.parentElement, classname)
  )
}

const handleCardClick = e => {
  e.stopPropagation()
  if (
    hasParentWithClass(e.target, 'card-with-modal') &&
    !hasParentWithClass(e.target, 'notModal')
  ) {
    const modalTrigger = e.currentTarget.querySelector('.modal-trigger-hidden')
    if (modalTrigger !== undefined) {
      modalTrigger.click()
    }
  }
}

function renderCard({
  cardBody,
  cardFooter,
  cardHeader,
  cardItems,
  icon,
  className,
  backgroundImage,
}) {
  const cardBgData = _.get(backgroundImage, 'localFile.childImageSharp.fluid')
  const cardBg = _.get(backgroundImage, 'fluid.src', '')
  return (
    <Card
      className={className}
      style={cardBgData ? {} : { backgroundImage: `url(${cardBg})` }}
      onClick={e => handleCardClick(e)}
    >
      {icon && (
        <span className="icon-img-wrapper">
          <ContentfulComponentImage {...icon} />
        </span>
      )}
      <div className="card-content">
        {cardHeader && (
          <ContentfulComponentText className="card-header" {...cardHeader} />
        )}
        {cardBody && (
          <ContentfulComponentText className="card-body" {...cardBody} />
        )}
        {cardItems &&
          cardItems.map(cardItem => (
            <CardItem key={`cardItem-${cardItem.id}`} {...cardItem} />
          ))}
        {cardFooter && (
          <ContentfulComponentText className="card-footer" {...cardFooter} />
        )}
      </div>
    </Card>
  )
}

function renderCardContent({
  cardBody,
  cardFooter,
  cardHeader,
  cardItems,
  icon,
  className,
  backgroundImage,
}) {
  const cardBgData = _.get(backgroundImage, 'localFile.childImageSharp.fluid')
  if (cardBgData) {
    return (
      <div className="background-wrapper">
        <BackgroundImage tag="div" fluid={cardBgData}>
          {renderCard({
            cardBody,
            cardFooter,
            cardHeader,
            cardItems,
            icon,
            className,
            backgroundImage,
          })}
        </BackgroundImage>
      </div>
    )
  } else {
    return renderCard({
      cardBody,
      cardFooter,
      cardHeader,
      cardItems,
      icon,
      className,
      backgroundImage,
    })
  }
}

export default function({
  cardBody,
  cardFooter,
  cardHeader,
  cardItems,
  icon,
  className,
  backgroundImage,
}) {
  return renderCardContent({
    cardBody,
    cardFooter,
    cardHeader,
    cardItems,
    icon,
    className,
    backgroundImage,
  })
}
