import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import { arrayHasEntry } from '../../../utils'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import styled from 'styled-components'

const AccordionWrapper = styled.div`
  @media screen and (min-width: 600px) {
    display: none;
  }
`

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 900,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: 100,
    left: 0,
    right: 0,
    margin: '0 auto',
    backgroundColor: '#fafafa',
    borderBottom: '20px solid #4a90e2',
    borderRadius: '5px',
    maxHeight: '80vh',
    overflow: 'auto',
    '& ul': {
      textAlign: 'left',
      fontSize: '13px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    '& li': {
      flex: '0 0 45%',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    '& p': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '27px',
      color: 'rgba(0, 0, 0, 0.8)',
    },
    '& strong': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '27px',
    },
  },
}))

export default function ContentfulModal({ content }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <button
        className="modal-trigger-hidden"
        type="button"
        onClick={e => setOpen(true | false)}
      >
        Open Modal
      </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: 'hsla(0,0%,100%,.8)',
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <button className="modal-close-btn" onClick={handleClose}>
            X
          </button>
          {arrayHasEntry(content) &&
            content.map(modalContentItem => (
              <div
                dangerouslySetInnerHTML={{
                  __html: modalContentItem.cardBody.childMarkdownRemark.html,
                }}
              />
            ))}
        </div>
      </Modal>

      <AccordionWrapper className="notModal">
        {arrayHasEntry(content) &&
          content.map(modalContentItem => (
            <Accordion
              square={true}
              expanded={expanded === `${modalContentItem.id}`}
              onChange={handleChange(`${modalContentItem.id}`)}
            >
              <AccordionSummary
                expandIcon={
                  expanded === `${modalContentItem.id}` ? (
                    <span className="notModal expand">-</span>
                  ) : (
                    <span className="notModal expand">+</span>
                  )
                }
                aria-controls={`${modalContentItem.id}d-content`}
                id={`${modalContentItem.id}d-header`}
                className="accordion-title"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: modalContentItem.title,
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion-summary">
                <div
                  dangerouslySetInnerHTML={{
                    __html: modalContentItem.cardBody.childMarkdownRemark.html,
                  }}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </AccordionWrapper>
    </div>
  )
}
