import React from 'react'
import styled from 'styled-components'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { arrayHasEntry } from '../../../utils'
import { makeStyles } from '@material-ui/core/styles';

import './Accordion.scss'

const AccordionWrapper = styled.div`
  padding: 50px 0;
`

const useStyles = makeStyles(({
  root: {
    margin: '0px',
    '& .accordion-title' : {
        borderTop: "1px solid #acabbd",
        backgroundColor: "#fafafa",
        color: "#d0021b",
        transition: ".7s",
        fontWeight: "600",
        fontSize: "14px",
        padding: "18px",
        '& > div': {
          margin: "0px",
          padding: "0px",
        }
    },
    '& span': {
      border: "unset",
      color: "#d0021b",
      fontSize: "14px",
      cursor: "pointer",
    },
    '& .accordion-summary': {
      backgroundColor: "#f0f0f0",
      fontSize: "14px",
      color: "rgba(44,51,55,.8)",
      padding: "20px 20px 20px",
      '& p' : {
        margin: "0px"
      }
    },
    '& .Mui-expanded': {
      margin: "0px"
    }
  },
}));

export default function({ accordionItems }) {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionWrapper className={classes.root}>
        {arrayHasEntry(accordionItems) &&
          accordionItems.map(accordionItem => (
            <Accordion elevation={0} square={true} expanded={expanded === `${accordionItem.id}`} onChange={handleChange(`${accordionItem.id}`)}>
            <AccordionSummary
              expandIcon={expanded === `${accordionItem.id}` ? "-" : "+"}
              aria-controls={`${accordionItem.id}d-content`}
              id={`${accordionItem.id}d-header`}
              className="accordion-title"
            >
              <div dangerouslySetInnerHTML={{
                __html: accordionItem.title,
              }} />
            </AccordionSummary>
            <AccordionDetails
            className="accordion-summary">
              <div dangerouslySetInnerHTML={{
                __html: accordionItem.body.childMarkdownRemark.html,
              }} />

            </AccordionDetails>
          </Accordion>
          ))}
    </AccordionWrapper>
  )
}
