import React from 'react'
import PropTypes from 'prop-types'

import { ChildMarkdownRemarkType } from '../../../types'

function Text({ childMarkdownRemark, use, className }) {
  switch (use) {
    case 'excerpt':
      return null
    case 'frontmatter':
      return null
    case 'headings':
      return null
    case 'html':
      return (
        <div
          dangerouslySetInnerHTML={{ __html: childMarkdownRemark.html }}
          className={className}
        />
      )
    default:
      return null
  }
}

Text.defaultProps = {
  childMarkdownRemark: ChildMarkdownRemarkType.isRequired,
  use: 'html',
}

Text.propTypes = {
  use: PropTypes.oneOf(['excerpt', 'frontmatter', 'headings', 'html']),
}

export default Text
