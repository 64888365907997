import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import LanguageContext from '../../Context'

const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  a {
    margin-left: 12px;
    text-decoration: none;
    color: inherit;
    &.btn-primary {
      width: fit-content;
      color: #d91616;
      background: #ffffff;
      &:hover {
        background: #d91616;
        cursor: pointer;
        color: #ffffff;
      }
    }
  }

  span {
    padding-left: 12px;
  }

  &.arrow-link {
    img {
      width: 62px;
      margin: 20px auto auto;
    }
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    color: #cf2518;
    text-transform: uppercase;
    padding-left: 0px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.26px;
    a {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      width: 170px;
      margin: auto;
      span {
        text-transform: uppercase;
        padding-left: 0;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.26px;
      }
    }
  }
`

function renderExternalLink({
  linkImage,
  title,
  className,
  languageState,
  externalLink,
  externalLinkFr,
}) {
  return (
    <LinkWrapper>
      {linkImage && <img src={linkImage.fluid.src} alt={title ? title : ''} />}
      <a
        href={
          languageState.lang === false && externalLinkFr
            ? externalLinkFr
            : externalLink
        }
        target={externalLink.includes('mailto') ? '' : '_blank'}
        rel="noopener noreferrer"
        className={className}
      >
        {title}
      </a>
    </LinkWrapper>
  )
}

function renderInternalLink({
  linkImage,
  title,
  link,
  className,
  languageState,
}) {
  return (
    <LinkWrapper className={className}>
      {linkImage && <img src={linkImage.fluid.src} alt={title ? title : ''} />}
      <Link to={languageState.lang === true ? '' + link : '/fr' + link}>
        {title}
      </Link>
    </LinkWrapper>
  )
}

export default function({
  title,
  link,
  externalLink,
  externalLinkFr,
  linkImage,
  className,
}) {
  const { languageState } = React.useContext(LanguageContext)

  return externalLink && externalLink.length > 0
    ? renderExternalLink({
        title,
        link,
        externalLink,
        externalLinkFr,
        linkImage,
        className,
        languageState,
      })
    : renderInternalLink({ title, link, linkImage, className, languageState })
}
