import { shape, string, arrayOf, number } from 'prop-types'

export const ChildMarkdownRemarkType = shape({
  html: string,
  frontmatter: shape({
    title: string,
  }),
  excerpt: string,
  headings: arrayOf(
    shape({
      value: string,
      depth: number,
    })
  ),
})
